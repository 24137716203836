import request from "@/utils/request";
import { BASE_API } from '@/utils/consts'

export async function queryCurrent() {
  return request(`${BASE_API}/users/show`);
}

export function login(data) {
  return request(`${BASE_API}/system/login`, {
    data,
    method: 'post'
  });
}

export function changePwd(data) {
  return request(`${BASE_API}/users/update_password`, {
    data,
    method: 'post'
  });
}

export function changeMyPwd(data) {
  return request(`${BASE_API}/users/update_my_password`, {
    data,
    method: 'PUT'
  });
}

export function addClinicAdmin(data) {
  return request(`${BASE_API}/users/set_admin`, {
    method: 'POST',
    data
  });
}

//设置应用程序默认角色
export function setDefaultRole(data) {
  return request(`${BASE_API}/role/application/setup_default_role`, {
    method: 'POST',
    data
  });
}

